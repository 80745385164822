@import "./Variables.scss";

#not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.center-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-100w {
  margin: 0 !important;
  height: 1000px;
  min-height: 100%;
  border-radius: 0;
}

.custom-map-modal {
  .modal-dialog {
    max-width: 600px;
    height: auto;
    padding: 0;
    margin: auto auto !important;
    padding: 0px!important;
  }
  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
    border: 0;
    background-color: var(--colors-productBg);
  }
}

#main-table.container {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  margin: auto !important;
  max-width: 1300px;
}

#main-table {
  .header {
    width: 100%;
  }

  .restaurant-info {
    padding: 10px;
    border-bottom: 1px solid lightgrey;
    .restaurant-name {
      color: black;
      font-size: 34px;
      font-weight: bold;
    }

    .restaurant-info-text {
      color: #383d41;
      font-size: 16px;
      white-space: pre-line;
      &.bullet:before {
        content: "\A";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: var(--website-color);
        display: inline-block;
        margin: 0 10px;
        vertical-align: middle;
      }
    }
    .restaurant-info-table {
      color: #383d41;
      font-size: 20px;
    }
    .cart-title {
      font-weight: bold;
      font-size: 20px;
      color: black;
      padding: 12px 0;
    }
  }

  .category-container {
    display: flex;
    width: 100%;
    padding: 0 10px;
    background-color: white;
    border-bottom: 1px solid #e6e6e6;
    white-space: nowrap;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none;
    scrollbar-color: transparent transparent;
    z-index: 1000;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      background: transparent;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
    .options {
      padding: 10px 0;
      overflow: auto;
      cursor: pointer;
      .options-btn {
        text-decoration: none;
        color: black;
        background: var(--color-grey);
        padding: 0.6rem 0.8rem;
        border-radius: 30px;
        border: 0;
        font-family: var(--font-family);
        font-size: 1rem;
        letter-spacing: 0.6px;
        display: inline-flex;
        align-items: center;
        margin-right: 0.3rem;
        svg {
          max-width: 20px;
          margin-right: 0.4rem;
        }
        &.selected {
          background: var(--website-color);
          color: white;
        }
      }
    }
  }

  .product-container {
    background-color: white;
    padding-bottom: 80px;
    flex: 1;
    .product-box {
      display: flex;
      position: relative;
      flex-direction: row;
      align-items: center;
      height: 150px;
      margin: 0;
      .product-box-quantity {
        position: absolute;
        top: 11px;
        right: 0;
        width: 30px;
        height: 30px;
        background-color: var(--website-color);
        text-align: center;
        line-height: 30px;
        font-size: 1.2em;
        color: white;
      }
      .product-text-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;
        .product-box-name {
          width: 100%;
          order: 1;
          display: flex;
          align-items: center;
          .icon {
            width: 18px;
            height: 18px;
          }
          .product-name {
            font-size: 18px;
            color: black;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
        .product-description {
          order: 2;
          font-size: 16px;
          color: #383d41;
          p {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin-bottom: 0;
          }
        }
        .product-price {
          order: 3;
          font-size: 18px;
        }
        .product-not-available {
          color: red;
        }
      }
      .img {
        height: 128px;
        width: 138px;
        object-fit: cover;
      }
    }
    .product-margin {
      height: 1px;
      background-color: #dedede;
    }
  }

  .section-container > div:last-child > .product-margin {
    height: 0px;
  }

  .section {
    position: relative;
    display: flex;
    align-items: center;
    padding: 5% 0% 1% 0%;
    .section-title {
      font-weight: bold;
      font-size: 20px;
      color: black;
      background-color: white;
      padding-right: 20px;
      z-index: 10;
      margin-left: 60px;
      padding-left: 20px;
      width: 100%;
    }
    &:after {
      content: "";
      height: 2px;
      position: absolute;
      background: #000000;
      left: 0;
      right: 0;
      z-index: 1;
    }
    .product-margin:last-child {
      height: 0px;
    }
  }

  .section-description {
    white-space: pre-wrap;
    padding-bottom: 20px;
    color: #191919;
    font-style: italic;
  }

  .product-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0 10px 20px 15px;
  }

  .discount-row {
    color: black;
    padding: 0 0 15px 0;

    &:hover {
      color: black;
    }
  }

  .footer {
    background-color: white;
    padding: 12px;
    z-index: 1000;
    &:hover {
      text-decoration: none;
    }
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    .confirm-btn {
      display: flex;
      justify-content: space-between;
      width: 100%;
      background-color: $mainColor;
      padding: 20px 10px;
      color: white;
      text-align: center;
      font-size: 22px;
      font-weight: 500;
      max-height: 75px;
      &:focus {
        text-decoration: none;
      }
      span:first-child {
        border: 1px solid white;
        min-width: 35px;
      }
    }
    .confirm-btn.disabled {
      background-color: gray;
    }
  }

  // sticky header
  /* The sticky class is added to the header with JS when it reaches its scroll position */
  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }

  /* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */
  .sticky + .breakpoint__medium-up {
    padding-top: 64px;
  }

  .sticky + .breakpoint__small-down {
    padding-top: 64px;
  }

  .main-color {
    color: $mainColor;
  }

  @media (min-width: 768px) {
    .message-separator {
      width: 100px;
      border-top-width: 3px;
      border-color: black;
      margin-left: 0;
    }
    .modal-100w {
      margin: 0 !important;
      height: 1000px;
      min-height: 100%;
      border-radius: 0;
    }
    .product-container {
      flex: 2.6 0;
      background-color: white;
      padding-bottom: 80px;
      .product {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .product-box {
        display: flex;
        flex-direction: row;
        flex-basis: 49%;
        height: 130px;
        max-width: 554px;
        min-width: 270px;
        border: 1px solid #e1e1e1;
        align-items: center;
        padding: 10px 0 10px 10px;
        margin: 10px 0;
        cursor: pointer;
        &:hover {
          border: 1px solid var(--website-color);
        }
        &.selected {
          border: 2px solid var(--website-color);
          .img {
            height: 126px;
          }
        }
        .product-box-quantity {
          top: 0px;
        }
        .product-text-container {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          .product-box-name {
            width: 100%;
            order: 1;
            display: flex;
            align-items: center;
            .icon {
              width: 15px;
              height: 15px;
            }
            .product-name {
              font-size: 18px;
              color: black;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
          .product-description {
            order: 2;
            font-size: 16px;
            color: #383d41;
            p {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              margin-bottom: 0;
            }
          }
          .product-price {
            order: 3;
            font-size: 18px;
          }
        }
        .img {
          height: 128px;
          width: 138px;
          object-fit: cover;
        }
      }
    }

    .section {
      position: relative;
      display: flex;
      align-items: center;
      height: 5vh;
      padding: 3% 0% 1% 0%;
      .section-title {
        font-weight: bold;
        font-size: 20px;
        color: black;
        background-color: white;
        padding-right: 20px;
        z-index: 10;
      }
      &:after {
        content: "";
        height: 2px;
        position: absolute;
        background: #000000;
        left: 0;
        right: 0;
        z-index: 1;
      }
    }

    .product-grid {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 0 0 20px 20px;
    }

    .cart {
      flex: 1 0;
      display: flex;
      flex-direction: column;
      height: 510px;
      box-shadow: rgba(0, 0, 0, 0.2) 1px 4px 10px;
      border-top: 0px;
      position: sticky;
      top: 63px;
      min-width: 320px;
      padding: 10px;
      margin-left: 20px;
      overflow: auto;
      .cart-title {
        font-weight: bold;
        font-size: 20px;
        color: black;
        padding: 12px 0;
      }
      .cart-content {
        flex-grow: 1;
        max-height: 320px;
        margin-bottom: 8px;
        overflow: auto;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 934px) {
    .product-container {
      .product-box {
        flex-basis: 100%;
      }
    }
  }
}
