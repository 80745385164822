@import "./Variables.scss";
@import './ProductCartRow.scss';

#confirm-page {
  max-width: 600px;

  h1 {
    color: black;
    font-size: 34px;
    font-weight: bold;
    text-align: center;
    margin: 30px 0;
  }

  h4 {
    color: black;
    font-weight: bold;
    padding: 0;
  }

  p {
    margin: 30px 0;
    text-align: center;
  }

  .capitalize::first-letter {
    text-transform: uppercase;
  }

  .confirm-recap {
    background-color: rgb(245, 245, 245);
    margin: 30px 0;
    padding: 15px;

    .confirm-recap-header {
      & h4:before {
        content: ' ';
        background-image: url('../icons/shopping_basket.svg');
        display: inline-block;
        width: 24px;
        height: 24px;
        vertical-align: bottom;
        margin-right: 10px;
      }
      &:after {
        content: ' ';
        display: block;
        border-bottom: 1px solid rgb(226, 226, 226);
        margin: 0 10px 10px;
      }
    }

    .confirm-recap-toggle {
      cursor: pointer;
      display: flex;
      justify-content: space-between;

      .confirm-recap-price:after {
        content: ' ';
        background-image: url('../icons/expand_more.svg');
        display: inline-block;
        width: 24px;
        height: 24px;
        vertical-align: bottom;
        margin-left: 5px;
      }
      &.expanded {
        .confirm-recap-price:after {
          content: ' ';
          background-image: url('../icons/expand_less.svg');
          display: inline-block;
          width: 24px;
          height: 24px;
          vertical-align: bottom;
          margin-left: 5px;
        }
      }
    }

    .cart-product-container {
      display: flex;
      flex-direction: column;

      &.hide {
        display: none;
      }

      hr {
        margin: 10px;
        border-top: 1px solid rgb(226, 226, 226);
      }

      .cart-product-total {
        &.big {
          font-size: 1.2em;
        }
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .confirm-address {
    margin: 30px 0;
  }

  .back-to-home {
    margin: 0 0 40px 0;
  }

  .confirm-email {
    &:before {
      content: ' ';
      background-image: url('../icons/email.svg');
      display: inline-block;
      width: 24px;
      height: 24px;
      vertical-align: bottom;
      margin-right: 10px;
    }
  }

  #question-recap-title {
    & h4:before {
      content: ' ';
      background-image: url('../icons/info.svg');
      display: inline-block;
      width: 24px;
      height: 24px;
      vertical-align: bottom;
      margin-right: 10px;
    }
  }

  @extend .cart-product-item;
  .cart-product-row {
    cursor: default
  }
}