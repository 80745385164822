@import "./Variables.scss";

.timepicker {
  flex: 1;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  padding: 1rem;

  .timepicker-slot {
    margin: 1rem 0;
    display: flex;
    flex-wrap: wrap;
  }

  .timepicker-slot span {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    width: 5rem;
    padding: 0.5rem 0;
    margin: 5px;
    border: 1px solid $mainColor;
  }

  .timepicker-slot span.selected {
    background-color: $mainColor;
    color: white;
  }

  .timepicker-slot span.disabled {
    opacity: 0.35;
    cursor: initial;
  }
}
