.footer-bar {
  padding: 30px;
  a {
    padding: 5px;
    color: black;
    &:hover {
      color: hotpink;
      text-decoration: none;
    }
  }
}
