.checkbox-wrapper input[type="checkbox"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 1.6em;
  height: 1.6em;
  border-radius: 0.15em;
  border: 0.15em solid #000;
  outline: none;
  cursor: pointer;
}

.checkbox-wrapper > label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  cursor: pointer;
}

.checkbox-wrapper input:checked {
  background-color: #000;
  position: relative;
}

.checkbox-wrapper input:checked::before {
  content: "\2714";
  font-size: 1.3em;
  color: #fff;
  position: absolute;
  right: 1px;
  top: -5px;
}
