.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .page-title {
    font-size: 28px;
    font-weight: 800;
    align-self: center;
  }
  .back-btn {
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 10px;
  }
}
