@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --colors-mainBg: #fff;
  --colors-main: #077DF4;
  --colors-mainText: #000;
  --colors-categoryBg:#fff;
  --colors-categoryBtnSelected: #077DF4;
  --colors-categoryText: #000;
  --colors-categoryTextSelected: #fff;
  --colors-productBg: #fff;
  --colors-imgBorder: #fff;
  --colors-categoryName: #000;
  --colors-productBtn: #fff;
  --colors-productName: #000;
  --colors-productDesciption: #000;
  --colors-productBorder: #f9f9f9;
  --colors-productPrice: #000;
}


body {
  margin: 0;
  font-family: "Cabin Condensed", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  background-color: var(--colors-mainBg)!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  touch-action: manipulation;
}

button:focus {
  outline: none !important;
}

input[type="checkbox"]{
  width: 22px;
  height: 22px;
}

input[type=radio] {
  width: 22px;
  height: 22px;
}