$mainColor: var(--website-color);

.footer-block {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  .confirm-btn {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: $mainColor;
    padding: 20px 10px;
    color: white;
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    max-height: 75px;
    &:focus {
      text-decoration: none;
    }
    span:first-child {
      border: 1px solid white;
      min-width: 35px;
    }
  }
  .confirm-btn.disabled {
    background-color: gray;
  }
}



.remove-coupon-button {
  display: inline-block;
  margin-right: 7px;
  padding: 0 7px;
  background-color: var(--website-color);
  color: white;
  cursor: pointer;
}


.order-type-pick {
  display: flex;
  .order-type-choice {
    position: relative;
    cursor: pointer;
    margin: 10px 5px;
    border-radius: 0.2rem;
    padding: 1.2rem 0;
    width: 120px;
    background-color: rgb(240, 240, 240);
    text-align: center;
    border: 2px solid lightgray;
  }

  .order-type-choice.selected {
    border: 2px solid var(--website-color);
  }

  .order-type-choice.disabled {
    cursor: initial;
    background-color: gray;
    color: white;
    border: none;
  }
}

@media (min-width: 768px) {
  .footer-block {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    .confirm-btn {
      display: flex;
      justify-content: space-between;
      width: 100%;
      background-color: $mainColor;
      padding: 20px 10px;
      color: white;
      text-align: center;
      font-size: 22px;
      font-weight: 500;
      max-height: 75px;
      &:focus {
        text-decoration: none;
      }
      span:first-child {
        border: 1px solid white;
        min-width: 35px;
      }
    }
  }
}
